import React from 'react';
import '../styles.css';

const Home = () => {
  return (
    <div className="home">
      <h1>tabul.app</h1>
      <p>Navigate using the links above to explore the Timeline or Voidboard.</p>
    </div>
  );
};

export default Home;