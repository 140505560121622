import React from 'react';
import '../styles.css';

const Voidboard = () => {
  return (
    <div className="voidboard-container">
      <h1>Voidboard</h1>
      <p>A blank space to unleash your creativity!</p>
    </div>
  );
};

export default Voidboard;
